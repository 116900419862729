@import "minima";

:root {
	color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
	body { 
		background-color: #220a00;
		color: rgb(228, 228, 228);
	}
	.site-title, .site-title:visited {
		color: rgb(228, 228, 228);
	}
	a, a:hover, a:any-link, a:visited, .site-nav .page-link {
		color: #b5e0ff;
	}
	.site-nav {
		background-color: #220a00;
		color: rgb(228, 228, 228);
	}
	code, pre {
		background-color: #220a00;
	}
	table {
		color: rgb(228, 228, 228);
		border-collapse: collapse;
		tr {
		  &:nth-child(even) {
			background-color: #220a00;
		  }
		}
		th {
			background-color: #220a00;
		}
	}	
}


#frontPageSpecialityNavigation {
	text-align: center;
}

#frontPageSpecialityNavigation li {
	list-style-type: none;
	display: inline-block;
	width: auto;
	padding: 0.5em;
	background-color: rgb(220, 53, 19); /* Pantone 3516 C */
	margin: 0.25em;
}

#frontPageSpecialityNavigation li a:link, #frontPageSpecialityNavigation li a:visited, #frontPageSpecialityNavigation li a:hover, #frontPageSpecialityNavigation li a:active {
  color: white;
}

body {
  font-family: Helvetica, Helvetica Neue, Helvetica, sans-serif;
}

/* Merged from latest GitHub source for minima to fix IE11 break */

.page-content {
  flex: 1 0 auto;
}

.site-title:after {
  content: "🔥";
}

fieldset {
	margin-bottom: 10px;
}
